
import {Component, Prop, Vue} from 'vue-property-decorator';
import moment from "moment-timezone";
import { IJobOffersWorker } from '@/types';
import {ROUTES, ECandidateOfferStatus} from "@/data";
import {Action} from "vuex-class";
import {StoreAction} from "@/types";
import { pluralize } from '@/helpers/commons';
import Tile from "@/components/tileContent/Tile.vue"
import JobOfferCandidateAvailableCommands from "../../ats/JobOffer/JobOfferCandidateAvailableCommands.vue"
import Avatar from '@/components/Avatar.vue';
import NewIcon from '@/components/NewIcon.vue';

@Component({
    name: "WorkerFollowingTile",
    components: {Tile, JobOfferCandidateAvailableCommands, Avatar, NewIcon}
})
export default class WorkerFollowingTile extends Vue {
    @Prop() readonly worker!: IJobOffersWorker;
    isCommandMenuOpen = false;

    get createdAt() {
        return moment(this.worker.addedToJobOfferAt).format(this.$t('date.shortFormat'))
    }

    get workerName() {
        const firstname = this.worker.firstname;
        const lastname  = this.worker.lastname;
        return `${ firstname ? firstname : ''} ${lastname ? lastname : ''}`
    }

    get workerPhone() {
        return this.worker.phone;
    }

    get workerGlobalStatus() {
        return this.$t("page.atsFollowing.global.headers." + this.worker.globalStatus);
    }

    get workerSource() {
        return this.worker.sourceInJobOffer;
    }

    get elapsedTime() {
        return Math.trunc(moment.duration({ from: this.worker.addedToJobOfferAt, to: Date.now() }).asDays());
    }

    get globalStatusClass() {
        return `global-status ${this.worker.globalStatus}`;
    }

    get elapsedTimeTitle() {
        return this.elapsedTime > 0 ?
            this.$t("page.atsFollowing.global.tile.elapsedTime.title", { elapsedTime: this.elapsedTime })
            : this.$t("page.atsFollowing.global.tile.new.title");
    }

    get elapsedTimeText() {
        return this.elapsedTime > 0 ?
            pluralize(<string>this.$t("page.atsFollowing.global.tile.elapsedTime.text", { elapsedTime: this.elapsedTime }), this.elapsedTime )
            : this.$t("page.atsFollowing.global.tile.elapsedTime.today");
    }

    get redirectTo() {
        return this.worker.isInterim ? this.goToInterim : this.goToCandidate;
    }

    goToCandidate() {
        this.$router.push({name: ROUTES.APP.ATS.CANDIDATE._ROOT, params: {candidateId: this.worker._id}});
    }

    goToInterim() {
        this.$router.push({name: ROUTES.APP.INTERIM._ROOT, params: {interimId: this.worker._id}});
    }

    updateWorkerStatus(workerStatus: ECandidateOfferStatus) {
        this.isCommandMenuOpen = false
        this.$emit('updateWorkerStatus', {workerId: this.worker._id, newStatus: workerStatus})
    }

}
